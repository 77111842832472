import React, { useContext } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import Video from "~components/Video";

const CaseStudyMarquee = ({ className, items, keyPrefix, speed }) => {
  const { device } = useContext(DocumentContext);

  // const [hovering, setHovering]

  const itemListJSX = (
    <ul
      className="case-study-marquee__list relative flex items-start justify-center"
      style={{
        animation: `${speed * 50}s linear marquee infinite`
      }}
    >
      {items.map(project => {
        const key = project.fields.slug;

        let mediaJSX;

        if (device && device === `desktop`) {
          if (
            project?.frontmatter?.homeCarouselVideoURL &&
            project.frontmatter.homeCarouselVideoURL !== ``
          ) {
            mediaJSX = (
              <Video
                className="w-full h-full relative block object-cover"
                src={project.frontmatter.homeCarouselVideoURL}
                controls={
                  project.frontmatter.homeCarouselVideoControls || false
                }
              />
            );
          } else if (
            project?.frontmatter?.homeCarouselImage?.childImageSharp?.fluid
          ) {
            mediaJSX = (
              <Img
                className="w-full h-full relative block object-cover"
                fluid={
                  project.frontmatter.homeCarouselImage.childImageSharp.fluid
                }
                alt={project.frontmatter.homeCarouselImageAlt}
              />
            );
          } else if (project?.frontmatter?.homeCarouselImage?.publicURL) {
            mediaJSX = (
              <img
                className="w-full h-full relative block object-cover"
                src={project.frontmatter.homeCarouselImage.publicURL}
                alt={project.frontmatter.homeCarouselImageAlt}
              />
            );
          }
        } else if (
          project?.frontmatter?.homeCarouselVideoURLXS &&
          project.frontmatter.homeCarouselVideoURLXS !== ``
        ) {
          mediaJSX = (
            <Video
              className="w-full h-full relative block object-cover"
              src={project.frontmatter.homeCarouselVideoURLXS}
              controls={project.frontmatter.homeCarouselVideoControls || false}
            />
          );
        } else if (
          project?.frontmatter?.homeCarouselImageXS?.childImageSharp?.fluid
        ) {
          mediaJSX = (
            <Img
              className="w-full h-full relative block object-cover"
              fluid={
                project.frontmatter.homeCarouselImageXS.childImageSharp.fluid
              }
              alt={project.frontmatter.homeCarouselImageAlt}
            />
          );
        } else if (project?.frontmatter?.homeCarouselImageXS?.publicURL) {
          mediaJSX = (
            <img
              className="w-full h-full relative block object-cover"
              src={project.frontmatter.homeCarouselImageXS.publicURL}
              alt={project.frontmatter.homeCarouselImageAlt}
            />
          );
        }

        return (
          <li key={key} className="case-study-marquee__item relative">
            <div className="square">
              <CursorHoverable>
                <Link
                  to={key}
                  className="w-full h-full absolute top-0 right-0 bottom-0 left-0"
                >
                  {mediaJSX && mediaJSX}
                </Link>
              </CursorHoverable>
            </div>

            <div className="case-study-marquee__item__meta w-full relative mt-v1 pb-v2 flex items-start justify-between">
              <h3
                className={`w-3/5 xs:w-full xs:mt-v2 pr-v3 ${
                  device === `desktop` ? `f5` : `b1`
                }`}
              >
                {project.frontmatter.title}
              </h3>

              <div className="w-2/5 desktop-only--flex justify-end text-right">
                <CursorHoverable>
                  <Link to={project.fields.slug}>
                    <h3 className="underline b1">
                      {project.fields.slug.includes(`/case-studies`)
                        ? `View Project`
                        : `Go to Archive`}
                    </h3>
                  </Link>
                </CursorHoverable>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );

  return (
    <section
      key={`${keyPrefix}-container`}
      className={`case-study-marquee ${className ||
        `w-full relative`} xs:pt-v8 overflow-hidden`}
    >
      <div className="w-full relative flex items-center justify-center">
        {itemListJSX}
        {itemListJSX}
      </div>
    </section>
  );
};

CaseStudyMarquee.defaultProps = {
  className: ``,
  keyPrefix: `glide-carousel`,
  speed: 1
};

CaseStudyMarquee.propTypes = {
  className: PropTypes.string,
  items: PropTypes.node.isRequired,
  keyPrefix: PropTypes.string,
  speed: PropTypes.number
};

export default CaseStudyMarquee;
