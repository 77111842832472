/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect, useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Glide from "@glidejs/glide";
import { DocumentContext } from "~context/DocumentContext";
import AppearOnScroll from "~components/AppearOnScroll";
import ExhibitionCTA from "~components/ExhibitionCTA";
import CursorHoverable from "~components/CursorHoverable";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import CaseStudyMarquee from "~components/CaseStudyMarquee";
import ScreenHeight from "~components/ScreenHeight";
import SEO from "~components/SEO";
import Video from "~components/Video";
import { splitStringToParagraphs } from "~utils/helpers";

const IndexPage = ({ data, location }) => {
  const { device, windowWidth } = useContext(DocumentContext);

  const bannerGlideRef = useRef();
  const touchBannerGlideRef = useRef();

  const [bannerGlide, setBannerGlide] = useState(null);
  const [bannerIndex, setBannerIndex] = useState(0);
  const [touchBannerGlide, setTouchBannerGlide] = useState(null);
  const [touchBannerIndex, setTouchBannerIndex] = useState(0);

  //

  const { allMarkdownRemark, markdownRemark } = data;
  const { frontmatter } = markdownRemark;
  const bannerProjects = [];
  const carouselProjects = [];

  const allProjects = [];

  allMarkdownRemark.edges.forEach(({ node }) => {
    allProjects.push(node);
  });

  allProjects.sort((a, b) => {
    const priorityA = a.frontmatter.priority;
    const priorityB = b.frontmatter.priority;

    if (priorityA < priorityB) {
      return -1;
    }

    if (priorityA > priorityB) {
      return 1;
    }

    return 0;
  });

  allMarkdownRemark.edges.forEach(({ node }) => {
    if (node?.frontmatter?.showInHomeBanner) {
      bannerProjects.push(node);
    }

    if (node?.frontmatter?.showInHomeCarousel) {
      carouselProjects.push(node);
    }
  });

  const activeBannerProject = bannerProjects[bannerIndex];

  //

  useEffect(() => {
    if (!bannerGlideRef?.current || !windowWidth || bannerGlide) {
      return;
    }

    const glide = new Glide(`#banner`, {
      autoplay: parseInt(frontmatter.bannerTimer) * 1000 || 3000,
      gap: 0,
      hoverpause: true,
      perView: 1,
      type: `carousel`
    });

    glide.on([`run`], () => {
      setBannerIndex(glide.index);
    });

    glide.mount();

    setBannerGlide(glide);
  }, [bannerGlideRef, windowWidth]);

  useEffect(() => {
    if (!touchBannerGlideRef?.current || !windowWidth || touchBannerGlide) {
      return;
    }

    const glide = new Glide(`#banner-touch`, {
      autoplay: false,
      gap: 0,
      perView: 1,
      type: `carousel`
    });

    glide.on([`run`], () => {
      setTouchBannerIndex(glide.index);
    });

    glide.mount();

    setTouchBannerGlide(glide);
  }, [touchBannerGlideRef, windowWidth]);

  //

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="index-page w-full relative bg-antiflash">
        <section className="index-page__banner animation-appear-down-slow animation-delay-1 desktop-only w-full sixteen-by-nine relative">
          <CursorHoverable className="w-full h-full absolute top-0 right-0 bottom-0 left-0 z-30">
            <Link
              to={activeBannerProject.fields.slug}
              className="w-full h-full relative block"
            />
          </CursorHoverable>

          <div className="animation-appear-slow animation-delay-2 w-full absolute bottom-0 right-0 left-0 z-10 flex justify-between pr-v3 pl-v3 pb-v2 text-white">
            <p className="w-1/3 b1">
              <span className="mr-v1 font-bold">
                {activeBannerProject.frontmatter.title}
              </span>
              <span>{activeBannerProject.frontmatter.tagline}</span>
            </p>

            {/* <div className="w-1/3 relative"></div> */}

            <div
              className={`index-page__tracker index-page__tracker--active-${bannerIndex} w-1/3 relative flex items-center justify-end text-right`}
            >
              {Array(bannerProjects.length)
                .fill(null)
                .map((item, itemIndex) => {
                  const active = itemIndex === bannerIndex;

                  return (
                    <>
                      {itemIndex !== 0 && itemIndex < bannerProjects.length && (
                        <div
                          className={`index-page__tracker__line index-page__tracker__line--${itemIndex} bg-white`}
                        ></div>
                      )}

                      <span className={`${active ? `` : `opacity-50`} b1`}>
                        {itemIndex + 1}
                      </span>
                    </>
                  );
                })}
            </div>
          </div>

          <div
            id="banner"
            ref={bannerGlideRef}
            className="glide w-full h-full relative"
          >
            <div className="glide__track h-full relative" data-glide-el="track">
              <ul className="glide__slides h-full relative">
                {bannerProjects.map(project => {
                  const key = project.fields.slug;

                  let mediaJSX;

                  if (device && device === `desktop`) {
                    if (
                      project?.frontmatter?.homeBannerVideoURL &&
                      project.frontmatter.homeBannerVideoURL !== ``
                    ) {
                      mediaJSX = (
                        <Video
                          className="w-full h-full relative block object-cover"
                          src={project.frontmatter.homeBannerVideoURL}
                          controls={
                            project.frontmatter.homeBannerVideoControls || false
                          }
                        />
                      );
                    } else if (
                      project?.frontmatter?.homeBannerImage?.childImageSharp
                        ?.fluid
                    ) {
                      mediaJSX = (
                        <Img
                          className="w-full h-full relative block object-cover"
                          fluid={
                            project.frontmatter.homeBannerImage.childImageSharp
                              .fluid
                          }
                          alt={project.frontmatter.homeBannerImageAlt}
                        />
                      );
                    } else if (
                      project?.frontmatter?.homeBannerImage?.publicURL
                    ) {
                      mediaJSX = (
                        <img
                          className="w-full h-full relative block object-cover"
                          src={project.frontmatter.homeBannerImage.publicURL}
                          alt={project.frontmatter.homeBannerImageAlt}
                        />
                      );
                    }
                  }

                  return (
                    <li key={key} className="glide__slide h-full relative">
                      {mediaJSX && mediaJSX}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>

        <ScreenHeight
          className="animation-appear-down animation-delay-2 touch-only"
          scale={0.95}
        >
          <div
            id="banner-touch"
            ref={touchBannerGlideRef}
            className="index-page__touch-carousel glide w-full h-full relative"
          >
            <div className="glide__track h-full relative" data-glide-el="track">
              <ul className="glide__slides h-full relative">
                {bannerProjects.map(project => {
                  const key = project.fields.slug;

                  let mediaJSX;

                  if (device && device !== `desktop`) {
                    if (
                      project?.frontmatter?.homeBannerVideoURLXS &&
                      project.frontmatter.homeBannerVideoURLXS !== ``
                    ) {
                      mediaJSX = (
                        <Video
                          className="w-full h-full relative block object-cover"
                          src={project.frontmatter.homeBannerVideoURLXS}
                          controls={
                            project.frontmatter.homeBannerVideoControls || false
                          }
                        />
                      );
                    } else if (
                      project?.frontmatter?.homeBannerImageXS?.childImageSharp
                        ?.fluid
                    ) {
                      mediaJSX = (
                        <Img
                          className="w-full h-full relative block object-cover"
                          fluid={
                            project.frontmatter.homeBannerImageXS
                              .childImageSharp.fluid
                          }
                          alt={project.frontmatter.homeBannerImageAlt}
                        />
                      );
                    } else if (
                      project?.frontmatter?.homeBannerImageXS?.publicURL
                    ) {
                      mediaJSX = (
                        <img
                          className="w-full h-full relative block object-cover"
                          src={project.frontmatter.homeBannerImageXS.publicURL}
                          alt={project.frontmatter.homeBannerImageAlt}
                        />
                      );
                    }
                  }

                  return (
                    <li key={key} className="glide__slide h-full relative">
                      <Link to={bannerProjects[touchBannerIndex].fields.slug}>
                        {mediaJSX && mediaJSX}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </ScreenHeight>

        {/* // */}

        <section className="w-full relative">
          <div className="grid">
            <div className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 mt-v6 xs:mt-v10 mb-v12 whitespace-pre-wrap">
              <AppearOnScroll once>
                {splitStringToParagraphs(
                  frontmatter.intro,
                  `mb-v2 xs:mb-v8 ${device === `desktop` ? `f3` : `f5`}`
                )}
              </AppearOnScroll>

              <CursorHoverable>
                <Link
                  to="/about"
                  className={`relative mt-v4 xs:mt-v8 block hover-underline ${
                    device === `desktop` ? `f5` : `b1`
                  }`}
                >
                  + Read more
                </Link>
              </CursorHoverable>
            </div>
          </div>
        </section>

        <CaseStudyMarquee
          speed={frontmatter.marqueeSpeed}
          items={carouselProjects}
        />

        <section className="w-full relative xs:pt-v8 pb-v8 xs:pb-v20">
          <div className="grid">
            <div className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 mt-v5 xs:mt-v16">
              <p
                className="whitespace-pre-wrap f3 mb-v2 xs:mb-v8"
                dangerouslySetInnerHTML={{ __html: frontmatter.statementUpper }}
              ></p>
              <p
                className="whitespace-pre-wrap f3 mb-v2 xs:mb-v8"
                dangerouslySetInnerHTML={{ __html: frontmatter.statementLower }}
              ></p>
            </div>

            <div
              className={`grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 mt-v2 ${
                device === `desktop` ? `f5` : `b1`
              } hover-underline text-left`}
            >
              <CursorHoverable>
                <a href="mailto:hello@with-you.studio">+ Get in touch</a>
              </CursorHoverable>
            </div>
          </div>
        </section>

        <ExhibitionCTA />
      </Layout>

      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        intro
        bannerTimer
        marqueeSpeed
        statementUpper
        statementLower
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "case-study-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tagline
            priority
            showInHomeBanner
            homeBannerImage {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            homeBannerImageXS {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            homeBannerImageAlt
            homeBannerVideoURL
            homeBannerVideoURLXS
            homeBannerVideoControls
            showInHomeCarousel
            homeCarouselImage {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            homeCarouselImageXS {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            homeCarouselImageAlt
            homeCarouselVideoURL
            homeCarouselVideoURLXS
            homeCarouselVideoControls
          }
        }
      }
    }
  }
`;
